<template>
    <div>

        <v-autocomplete :rules="rules" :prepend-icon="icon ? icons.mdiAccount : null" v-model="model" :items="items"
            :filter="customFilter" :loading="isLoading" :search-input.sync="search" clearable hide-details hide-selected
            item-text="nombre" item-value="id" label=" Buscar producto " outlined dense>
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title>
                        No hay resultados
                    </v-list-item-title>
                </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
                <span>{{ item.producto.nombre }}</span>
            </template>
            <template v-slot:item="{ item }">

                <v-list-item-avatar color="primary" size="30" class=" my-2 py-2 font-weight-light white--text">
                    <small>{{ item.producto.nombre.charAt(0) }}{{ item.producto.nombre.charAt(1) }}</small>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.producto.nombre }}
                        <br>
                        <small> {{ item.producto.barra }}</small>
                    </v-list-item-title>

                </v-list-item-content>

            </template>
        </v-autocomplete>

    </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { mdiAccount } from '@mdi/js'
import ProductoSucursalServices from '@/api/servicios/ProductoSucursalServices'
export default {
    props: {
        value: Object,
        icon: Boolean,
        idPadre: Number,
        rules: Array
    },
    watch: {
        idPadre: function (newVal, oldVal) {
            //alert("idPadre" + newVal + ', ' + oldVal)
            this.items = []
        }
    },
    setup(props, context) {
        const dialog = ref(false)
        const isLoading = ref(false)
        const items = ref([])
        const model = ref(null)
        const search = ref(null)
        const tab = ref(null)
        const customFilter = (item, queryText, itemText) => {
            const filtro1 = item.producto.nombre?.toUpperCase()
            const filtro2 = item.producto.descripcion?.toUpperCase()
            const filtro3 = item.producto.descripcionLargo?.toUpperCase()
            const filtro4 = item.producto.barra?.toUpperCase()
            const filtro5 = item.producto.referencia?.toUpperCase()
            const filtro6 = item.producto.referenciaFabrica?.toUpperCase()
            const searchText = queryText.toUpperCase()

            return filtro1?.indexOf(searchText) > -1 ||
                filtro2?.indexOf(searchText) > -1 ||
                filtro3?.indexOf(searchText) > -1 ||
                filtro4?.indexOf(searchText) > -1 ||
                filtro5?.indexOf(searchText) > -1 ||
                filtro6?.indexOf(searchText) > -1
        }
        watch(props, () => {
            if (props.value != null) {
                if (model.value != props.value.id) {
                    search.value = props.value.producto.nombre
                }

                model.value = props.value.id
            }

        })

        watch(model, () => {

            if (model.value != null && model.value != -1) {

                context.emit('input', items.value.find(item => (item.id == model.value)))

            } else {

                context.emit('input', { id: -1, producto: { indLote: 0 } })
            }



        }

        )
        watch(search, () => {
            if (search.value != undefined && search.value != null) {
                Buscar( )
                // //alert("buscando" + search.value+ ','+ props.idPadre)
            }

        }

        )


        const Buscar = ( ) => {
            // Items have already been loaded
            /*if (items.value.length > 0)
                return*/

            if (isLoading.value == true)
                return

            isLoading.value = true
            setTimeout(() => {
                // Lazily load input items 
                ProductoSucursalServices.ConsultarBuscar(search.value, props.idPadre)
                    .then(response => {
                        if (response.data.estatus == true) {
                            if (response.data.datos != null) {

                                items.value = response.data.datos

                                if (model.value != null && model.value != -1) {

                                    context.emit('input', items.value.find(item => (item.id == model.value)))

                                } else {

                                    context.emit('input', { id: -1, producto: { indLote: 0 } })
                                }
                            } else {
                                items.value = []
                            }
                        } else {
                            items.value = []
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (
                        isLoading.value = false
                    ))
            }, 1000);
        }


        return {
            icons: {
                mdiAccount
            },
            dialog,
            isLoading,
            items,
            model,
            search,
            tab,
            customFilter
        }
    },
}
</script>