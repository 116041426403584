<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
            Listar
        </v-card-subtitle>
    </v-card>
 </template>
 
 <<script>
 export default {
    setup() {
        
    },
 }
 </script>