<template>
  <div>
    <v-btn small color="red lighten-2" dark @click="dialog = true"> Lote </v-btn>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Lotes </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" v-if="ProductoSucursal != null">
              <p class="my-0">{{ ProductoSucursal.producto.barra }}</p>
              <p class="my-0">{{ ProductoSucursal.producto.nombre }}</p>
            </v-col>
          </v-row>
          <v-row v-if="!nuevo">
            <v-col cols="8">
              <v-autocomplete
                clearable
                outlined
                dense
                label="Lotes"
                v-model="loteSelect"
                :items="lotes"
                item-text="numeroLote"
                item-value="id"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.numeroLote }} (FV:{{ FuncionesGenerales.formatoFecha(data.item.fechaVencimiento) }})
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.numeroLote }} (FV:{{ FuncionesGenerales.formatoFecha(data.item.fechaVencimiento) }})
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-btn color="primary" @click="nuevo = true">Nuevo</v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-form ref="form" lazy-validation>
                <v-text-field
                  outlined
                  dense
                  label="NumeroLote"
                  :rules="reglas.numeroLote"
                  v-model="lote.numeroLote"
                ></v-text-field>
                <DatePicher
                  :dense="true"
                  :label="'Fecha Expedicion'"
                  :rules="reglas.fechaExpedicion"
                  :outlined="true"
                  v-model="lote.fechaExpedicion"
                ></DatePicher>

                <DatePicher
                  :dense="true"
                  :label="'Fecha Vencimiento'"
                  :rules="reglas.fechaVencimiento"
                  :outlined="true"
                  v-model="lote.fechaVencimiento"
                ></DatePicher>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :loading="guardando" @click="seleccionar()"> Seleccionar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import LoteServices from '@/api/servicios/LoteServices'
import FuncionesGenerales from '@/funciones/funciones'
import store from '@/store'
import DatePicher from '@/components/DatePicker.vue'
import moment from 'moment'
export default {
  components: {
    DatePicher,
  },
  props: {
    ProductoSucursal: Object,
  },
  setup(props, contex) {
    const dialog = ref(false)
    const lotes = ref([])
    const nuevo = ref(false)
    const guardando = ref(false)
    const loteSelect = ref(null)
    const form = ref(null)
    const reglas = {
      numeroLote: [v => !!v || 'numeroLote es requerido'],
      fechaVencimiento: [v => !!v || 'fechaVencimiento es requerido'],
      fechaExpedicion: [v => !!v || 'fechaExpedicion es requerido'],
    }
    const lote = ref({
      id: -1,
      numeroLote: '',
      fechaVencimiento: moment().format('YYYY-MM-DD'),
      fechaExpedicion: moment().format('YYYY-MM-DD'),
      indActivo: true,
    })

    onBeforeMount(() => {
      consultarDatos()
    })

    watch(dialog, () => {
      if (dialog.value == true) {
        consultarDatos()
        nuevo.value = false
        guardando.value = false
      }
    })
    const cargando = ref(false)

    const seleccionar = () => {
      if (nuevo.value == true) {
        guardar()
      } else {
        if (loteSelect.value != null) {
          const lt = lotes.value.find(i => i.id == loteSelect.value)
          retornar(lt)
        }
      }
    }

    const retornar = dato => {
      contex.emit('GetLote', dato)
      dialog.value = false
    }
    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }
    const guardar = () => {
      if (validarForm()) {
        guardando.value = true
        try {
          LoteServices.Actualizar(store.state.user.idUsuario, {
            Id: lote.value.id,
            IdProducto: props.ProductoSucursal.idProducto,
            NumeroLote: lote.value.numeroLote,
            FechaExpedicion: lote.value.fechaExpedicion,
            FechaVencimiento: lote.value.fechaVencimiento,
            IndActivo: lote.value.indActivo,
          })
            .then(response => {
              if (response.data.estatus == true) {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                retornar(response.data.datos.datos)
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                  type: 'warning',
                })
              }
            })
            .catch(err => {
              store.commit('setAlert', {
                message: err,
                type: 'error',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
          guardando.value = false
        }
      } else {
        store.commit('setAlert', {
          message: 'Verificar los datos suministrados',
          type: 'info',
        })
      }
    }
    const consultarDatos = () => {
      cargando.value = true
      try {
        LoteServices.Consultar({
          Id: -1,
          IdProducto: props.ProductoSucursal.idProducto,
          numeroLote: '',
          indActivo: true,
        })
          .then(response => {
            if (response.data.estatus == true) {
              lotes.value = response.data.datos
            }
          })
          .catch(err => {
            //alert(err)
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        //alert(error)
        cargando.value = false
      }
    }

    return {
      dialog,
      consultarDatos,
      nuevo,
      guardar,
      lotes,
      FuncionesGenerales,
      lote,
      seleccionar,
      loteSelect,
      reglas,
      form,
      guardando,
    }
  },
}
</script>