<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
            Fabricacion
        </v-card-subtitle>
        <v-card-text>
            <v-row class="mb-1">
                <v-col lg="8" md="6" cols="12">
                    <v-row>
                        <v-col cols="12" md="6">
                            <AreaSelect :label="'Area Origen'" :clearable="true" v-model="datos.idAreaOrigen" :dense="true"
                                :outlined="true">
                            </AreaSelect>

                        </v-col>
                        <v-col cols="12" md="6">
                            <AreaSelect :label="'Area Destino'" :clearable="true" v-model="datos.idAreaDestino"
                                :dense="true" :outlined="true">
                            </AreaSelect>
                        </v-col>
                        <v-col cols="4">
                            <date-picker v-model="datos.fechafabricacion" :label="`Fecha Fabricacion`" :dense="true"
                                :outlined="true"></date-picker>
                        </v-col>
                        <v-col :cols="indLote(datos.productoSucursal) ? 5 : 8">
                            <select-producto-sucursal v-model="datos.productoSucursal"
                                :idPadre="store.state.sucursalSelect.id"></select-producto-sucursal>
                        </v-col>

                        <v-col cols="3" v-if="indLote(datos.productoSucursal)">
                            <Lote v-if="indLote(datos.productoSucursal) && !indLoteSelect(datos)" @GetLote="GetLote"
                                :ProductoSucursal="datos.productoSucursal">
                            </Lote>

                            <p v-if="indLoteSelect(datos)">Lote: {{ datos.lote.numeroLote }} (FV:{{
                                FuncionesGenerales.formatoFecha(datos.lote.fechaVencimiento)
                            }})
                                <v-btn icon color="secundary" @click="datos.lote = {}">
                                    <v-icon>{{ icons.mdiCached }}</v-icon>
                                </v-btn>
                            </p>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea outlined label="Observacion"></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>

                <v-divider vertical> </v-divider>
                <v-col lg="4" md="6" cols="12">

                    <v-text-field v-model="datos.cantidad" :suffix="UnidadMedida(datos.productoSucursal).abreviatura"
                        :label="`Cantidad ${UnidadMedida(datos.productoSucursal).nombre}`" required outlined dense
                        hide-details="auto" class="mb-6  "></v-text-field>
                    <v-text-field readonly v-model="datos.costoTotal" label="Costo Total" required outlined dense
                        hide-details="auto" class="mb-6 mt-5"
                        :suffix="FuncionesGenerales.monedaPrincipal().simbolo"></v-text-field>

                    <v-text-field readonly v-model="datos.costo" :suffix="FuncionesGenerales.monedaPrincipal().simbolo"
                        :label="`Costo x ${UnidadMedida(datos.productoSucursal).nombre}`" required outlined dense
                        hide-details="auto" class="mb-6 mt-5"></v-text-field>
                    <v-text-field readonly v-model="datos.factorVenta"
                        :suffix="FuncionesGenerales.monedaPrincipal().simbolo" :label="`Factor Venta`" required outlined
                        dense hide-details="auto" class="mb-6 mt-5"></v-text-field>
                    <v-text-field readonly v-model="datos.pvpSugerido"
                        :suffix="FuncionesGenerales.monedaPrincipal().simbolo" :label="`Pvp Sugerido`" required outlined
                        dense hide-details="auto" class="mb-6 mt-5"></v-text-field>
                </v-col>
            </v-row>
            <v-divider> </v-divider>
        </v-card-text>
        <v-card-text>
            <v-row class="mb-1">
                <v-col>
                    <v-card-subtitle>Ingredientes</v-card-subtitle>
                </v-col>

                <v-col class="text-right">
                    <v-btn class="ml-2" @click="abrirModalBusquedaProductoA()" small color="primary">Busqueda
                        avanzada</v-btn>
                </v-col>
                <v-col>
                    <BuscardorProductoBarra v-model="producto" ref="BuscardorProductoBarraRef"></BuscardorProductoBarra>
                </v-col>
            </v-row>

            <v-simple-table fixed-header height="300px" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Producto
                            </th>
                            <th class="text-left">
                                Lote
                            </th>
                            <th class="text-right">
                                Unidad Medida
                            </th>
                            <th class="text-right">
                                Costo
                            </th>
                           
                            <th class="text-right">
                                Cantidad
                            </th> 
                            <th class="text-right">
                                Total
                            </th>
                            <th class="text-center">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in datos.fabricacionDetalle" :key="i">
                            <td @click="editar(item)">
                                <small> {{
                                    item.productoSucursal.producto.barra
                                }} </small>
                                <br>
                                {{ item.productoSucursal.producto.nombre }}
                                
                               
                            </td>
                            <td>
                                <small v-if="item.productoSucursal.lote">  {{
                                    FuncionesGenerales.camelCase(item.productoSucursal.lote.numeroLote)
                                }} </small>
                            </td>
                            <td class="text-right" @click="editar(item)">
                                {{ item.productoSucursal.producto.unidadMedida.nombre }}
                            </td>
                            <td class="text-right" @click="editar(item)">
                                {{ item.costo.toFixed(2) }}
                            </td>
                            <td class="text-right" @click="editar(item)">
                                {{ item.cantidad }}    {{ item.productoSucursal.producto.unidadMedida.abreviatura }} 
                            </td>
                             
                            <td class="text-right" @click="editar(item)">
                                {{ (item.cantidad * item.costo).toFixed(2) }}
                            </td>
                            <td class="text-center">
                                <v-btn @click="eliminarItem(item)" color="error" icon x-small>
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <ModalBusquedaProductoAvanzado @GetProductos="GetProductos" :columnas="['ID', 'NOMBRE', 'CODIGO', 'EXISTENCIA']"
            :fullscreen="true" :soloExistencia="false" :IndExistencia="false" ref="ModalBusquedaProductoAvanzadoRef"
            :idArea="datos.idAreaOrigen">
        </ModalBusquedaProductoAvanzado>
    </v-card>
</template>
 
<script>
import { ref } from '@vue/composition-api'
import AreaSelect from '../../../archivo/area/componentes/AreaSelect.vue'
import store from '@/store'
import DatePicker from '@/components/DatePicker.vue'
import SelectProductoSucursal from '@/views/sistema/producto/catalogo/producto/componentes/SelectProductoSucursal.vue'
import Lote from '../../../lote/Lote.vue'
import { mdiCached } from '@mdi/js'
import FuncionesGenerales from '@/funciones/funciones'
import BuscardorProductoBarra from '@/views/sistema/producto/catalogo/producto/componentes/BuscardorProductoBarra.vue'
import ModalBusquedaProductoAvanzado from '@/views/sistema/producto/catalogo/producto/componentes/ModalBusquedaProductoAvanzado.vue'
export default {
    components: {
        AreaSelect,
        DatePicker,
        SelectProductoSucursal,
        Lote,
        BuscardorProductoBarra,
        ModalBusquedaProductoAvanzado
    },
    setup() {
        const ModalBusquedaProductoAvanzadoRef = ref()
        const fabricacionInit = {
            fechafabricacion: null,
            id: 0/*int*/,
            idProductoSucursal: 0/*int*/,
            idLote: 0/*int*/,
            idAreaOrigen: 0/*int*/,
            idAreaDestino: 0/*int*/,
            idEstatus: 0/*int*/,
            observacion: ""/*string*/,
            costo: 0/*decimal*/,
            costoTotal: 0/*decimal*/,
            cantidad: 0/*decimal*/,
            factorVenta: 0,
            pvpSugerido: 0,
            idUsuarioCrea: 0/*int*/,
            idUsuarioModifica: 0/*int*/,
            fechaCreacion: null/*DateTime*/,
            fechaModificacion: null/*DateTime*/,
            fabricacionDetalle: [],
            lote: {},
            productoSucursal: {},
            areaOrigen: {},
            areaDestino: {},
            estatus: {},
        }
        const fabricacionDetalleInit = {
            id: 0/*int*/,
            idFabricacion: 0/*int*/,
            idProductoSucursal: 0/*int*/,
            idLote: 0,
            idEstatus: 0/*int*/,
            cantidad: 0/*decimal*/,
            costo: 0/*decimal*/,
            idUsuarioCrea: 0/*int*/,
            idUsuarioModifica: 0/*int*/,
            fechaCreacion: null/*DateTime*/,
            fechaModificacion: null/*DateTime*/,
            ProductoSucursal: {},
            Estatus: {},
        }
        const datos = ref({ ...fabricacionInit })
        const abrirModalBusquedaProductoA = () => {

            ModalBusquedaProductoAvanzadoRef.value.abrirModal(true)
        }
        let identity = -10000
        const identityGlobal = () => {
            identity = identity + 1
            return identity
        }
        const GetProductos = (datosIn) => {
            console.log(datosIn)

            datosIn.forEach(element => {
                let existeProducto = false
                if (datos.value.fabricacionDetalle.find(it => it.idProductoSucursal == element.id && (element.lote == null || it.idLote == element.lote.id))) {

                    existeProducto = true
                } else {

                    existeProducto = false
                }

                if (!existeProducto) {
                    datos.value.fabricacionDetalle.push({
                        id: identityGlobal()/*int*/,
                        idProductoSucursal: element.id/*int*/,
                        idLote: element.lote ? element.lote.id : null,
                        idEstatus: 1/*int*/,
                        cantidad: 0/*decimal*/,
                        costo: element.costo/*decimal*/,
                        productoSucursal: element,
                    })
                }
            });
        }
        const indLote = (item) => {
            let val = false
            if (item?.producto?.indLote == true) {
                val = true
            }
            return val
        }
        const indLoteSelect = (item) => {
            let val = false
            if (item?.lote?.id > 0) {
                val = true
            }
            return val
        }

        const GetLote = (dato) => {
            datos.value.lote = dato
        }
        const UnidadMedida = (dato) => {
            let val = {
                id: 1,
                abreviatura: "U",
                nombre: "Unidad"
            }
            if (dato?.producto?.unidadMedida.id > 0) {
                val = dato?.producto?.unidadMedida
            }

            return val
        }

        return {
            datos,
            AreaSelect,
            store,
            indLote,
            GetLote,
            indLoteSelect,
            icons: {
                mdiCached
            },
            FuncionesGenerales,
            UnidadMedida,
            GetProductos,
            ModalBusquedaProductoAvanzadoRef,
            abrirModalBusquedaProductoA
        }

    },
}
</script>